@import '/src/styles/variables.scss';

@mixin layout-wrapper {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }
}

@mixin layout-bottom {
  margin-top: auto;
}

@mixin layout-with-nav {
  @include layout-wrapper;
  min-height: calc(var(--tg-viewport-height) - $nav-height);
  overflow: auto;

  @supports (min-height: 100dvh) {
    min-height: calc(100dvh - $nav-height);
  }
}

.layout-wrapper {
  @include layout-wrapper;
}

.layout-footer {
  @include layout-bottom;
}
