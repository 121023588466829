$white: #fff;
$dark: #000000;
$dark-gray: #171717;
$dark-light: #242424;
$lightest-gray: #f5f5f5;
$light-gray: #777777;
$soft-gray:#D9D9D9;
$dark-green: #0b150a;
$medium-gray: #4f4f4f;
$vivid-red: #ee2222;
$bright-red: #cd081e;
$gray-border: #eaecf0;
$green-light: #f2fcf3;
$green: #08cd1f;
$yellow-light: #fefaf4;
$yellow: #eea922;
$red-light: #fef4f4;
$charcoal-gray:#4F4F4F;

$breakpoints: (
  'xxs': 414px,
  'xs': 515px,
  'sm': 768px,
  'md': 992px,
  'lg': 1024px,
  'xl': 1200px
);

$color-black-transparent: rgba(0, 0, 0, 0.4);

$nav-height: 78px;

$font-famlily: 'Satoshi Variable', sans-serif;