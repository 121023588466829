@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Italic.eot');
    src: local('Satoshi Italic'), local('Satoshi-Italic'),
        url('../fonts/satoshi/Satoshi-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/satoshi/Satoshi-Italic.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Italic.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Black.eot');
    src: local('Satoshi Black'), local('Satoshi-Black'),
        url('../fonts/satoshi/Satoshi-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/satoshi/Satoshi-Black.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Black.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Black.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-BlackItalic.eot');
    src: local('Satoshi Black Italic'), local('Satoshi-BlackItalic'),
        url('../fonts/satoshi/Satoshi-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/satoshi/Satoshi-BlackItalic.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-BlackItalic.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-BlackItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Regular.eot');
    src: local('Satoshi Regular'), local('Satoshi-Regular'),
        url('../fonts/satoshi/Satoshi-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/satoshi/Satoshi-Regular.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Regular.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-LightItalic.eot');
    src: local('Satoshi Light Italic'), local('Satoshi-LightItalic'),
        url('../fonts/satoshi/Satoshi-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/satoshi/Satoshi-LightItalic.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-LightItalic.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Medium.eot');
    src: local('Satoshi Medium'), local('Satoshi-Medium'),
        url('../fonts/satoshi/Satoshi-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/satoshi/Satoshi-Medium.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Medium.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-BoldItalic.eot');
    src: local('Satoshi Bold Italic'), local('Satoshi-BoldItalic'),
        url('../fonts/satoshi/Satoshi-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/satoshi/Satoshi-BoldItalic.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-BoldItalic.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Bold.eot');
    src: local('Satoshi Bold'), local('Satoshi-Bold'),
        url('../fonts/satoshi/Satoshi-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/satoshi/Satoshi-Bold.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Bold.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Light.eot');
    src: local('Satoshi Light'), local('Satoshi-Light'),
        url('../fonts/satoshi/Satoshi-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/satoshi/Satoshi-Light.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Light.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-MediumItalic.eot');
    src: local('Satoshi Medium Italic'), local('Satoshi-MediumItalic'),
        url('../fonts/satoshi/Satoshi-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/satoshi/Satoshi-MediumItalic.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-MediumItalic.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

