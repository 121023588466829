@import '../variables.scss';

@mixin title-style {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: $white;
}
@mixin label-style {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

@mixin message-style {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

@mixin info-style {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

@mixin dots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin font-normal-satoshi {
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
}

@mixin disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@mixin h1 {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.64px;
}

@mixin p4 {
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
}

@mixin common-letter-spacing {
  letter-spacing: -0.02em;
}
