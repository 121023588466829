@import '/src/styles/mixins/typography.scss';
@import '/src/styles/variables.scss';
.loaderWrapper {
  margin-top: 48px;
  text-align: center;
}
.title {
    font-size: 32px;
    font-weight: 700;
    line-height: 43px;
    color: $dark-green;
    margin: 0;
}
.info {
    @include message-style;

    margin-top: 4px;
    color: $light-gray;
}
.loaderImage {
  width: 150px;
  height: 150px;
  margin: 0 auto;
}